"use client"
import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getDictionary } from '@/dictionaries';

const Map = ({ params }: { params: { lang: string } }) => {
  const [selectedImage, setSelectedImage] = useState<string>('/img/indonesia.png');
  const [fadeIn, setFadeIn] = useState<boolean>(true);
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});
  const [buttonLokalColor, setButtonLokalColor] = useState<string>('#78D2F7');
  const [buttonGlobalColor, setButtonGlobalColor] = useState<string>('#EAF7FD');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = (button: string, image: string) => {
    setFadeIn(false);

    setTimeout(() => {
      if (button === 'lokal') {
        setButtonLokalColor('#78D2F7');
        setButtonGlobalColor('#EAF7FD');
      } else {
        setButtonLokalColor('#EAF7FD');
        setButtonGlobalColor('#78D2F7');
      }

      setSelectedImage(image);
      setFadeIn(true);
    }, 500);
  };

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  return (
    <div>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '40px',
        marginTop: '40px'

      }}>
        <button
          onClick={() => handleButtonClick('lokal', '/img/indonesia.png')}
          className='buttonLokal'
          style={{ backgroundColor: buttonLokalColor }}
        >
          {dictionary.buttonMapLocal}
        </button>
        <button
          onClick={() => handleButtonClick('global', '/img/world1.png')}
          className='buttonGlobal'
          style={{ backgroundColor: buttonGlobalColor }}
        >
          Global
        </button>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '30px 0',
        opacity: fadeIn ? 1 : 0,
        transition: 'opacity 0.5s ease',
      }}>
        <Box sx={{ width: '1120px', height: isSmallScreen ? '168px' : '648px', overflow: 'hidden' }}>
          <img src={selectedImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </Box>
      </Box>
    </div>
  )
}

export default Map
