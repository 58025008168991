"use client"
import Map from './map'
import React from 'react'
import '../../../globals.css'
import { Box } from '@mui/material'
import WaterDrop from './waterDrop'

const Page = ({ params }: { params: { lang: string } }) => {

  return (
    <div>
      <Box>
        <WaterDrop params={{ lang: params.lang }} />
      </Box>
      <Box
        sx={{
          background: 'linear-gradient(180deg, rgba(24, 87, 108, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%), #333',
          height: 'auto',
          width: '100%',
          marginTop: '140px',
          padding: 0
        }}
      >
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <img src="/img/ArcTop.png" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Box sx={{ marginTop: '-140px', width: '100%', position: 'relative', zIndex: 3 }}>
          <Map params={{ lang: params.lang }} />
        </Box>
        <Box sx={{ width: '100%', overflow: 'hidden', }}>
          <img src="/img/ArcBottom.png" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', marginBottom: '-8px' }} />
        </Box>
      </Box>
    </div>
  )
}

export default Page
