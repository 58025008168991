"use client"
import '../../../globals.css';
import { getDictionary } from '@/dictionaries';
import FadeRight from '@/lib/dataAos/fadeRight';
import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';

const WaterDrop = ({ params }: { params: { lang: string } }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleMouseEnter = (index: any) => {
    setActiveIndex(index);
  };

  return (
    <Box position={'static'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          position: 'absolute',
          zIndex: 1000,
          width: '100%',
          overflow: 'hid'
        }}
      >
        <Box className="waterDropContainer">
          <Box className="waterDropBox">
            <img src="/img/waterDrop.png" className='imgCover' alt="" />
          </Box>

          <Box className="waterdropMobile">
            <img src="/img/waterdropmobile.png" className='imgCover' alt="" />
          </Box>


          <Box sx={{
            position: 'relative',
            zIndex: 1002,
          }}
            className="circleWaterDrop"
          >
            <Box className="circle-box">
              <img
                src="/img/pengujian-2.jpg"
                alt=""
                className={activeIndex === 1 ? 'active' : ''}
              />
              <img
                src="/img/produksi.jpg"
                alt=""
                className={activeIndex === 0 ? 'active' : ''}
              />
              <img
                src="/img/riset.jpg"
                alt=""
                className={activeIndex === 2 ? 'active' : ''}
              />
              <img
                src="/img/stress-busters.png"
                alt=""
                className={activeIndex === 3 ? 'active' : ''}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="productionContainerMain">
        <Container maxWidth="lg" className="productionContainer">
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8} >
              <Box>
                <FadeRight duration='700'>
                  <Typography className='titleProduction'>
                    {dictionary.titleProduction}
                  </Typography>
                </FadeRight>
                <FadeRight duration='700'>
                  <Box mt={3} sx={{ display: 'flex' }}>
                    {params.lang === 'id' ? (
                      <Typography className='subTitleProduction'>
                        <span style={{ color: '#1A1A1A' }}>Semua tentang</span>{' '}
                        <span style={{ color: '#18576C' }}>kegigihan</span>{' '}
                        <span style={{ color: '#1A1A1A' }}>memberikan yang</span>{' '}
                        <span style={{ color: '#18576C' }}>terbaik</span>
                      </Typography>
                    ) : (
                      <Typography className='subTitleProduction'>
                        <span style={{ color: '#1A1A1A' }}>It&apos;s all about</span>{' '}
                        <span style={{ color: '#18576C' }}>persistence</span>{' '}
                        <span style={{ color: '#1A1A1A' }}>to give your</span>{' '}
                        <span style={{ color: '#18576C' }}>best</span>
                      </Typography>
                    )}
                  </Box>
                </FadeRight>
              </Box>
            </Grid>
          </Grid>
          <Box className="listProductionContainer">
            <FadeRight duration='700'>
              <Box className="listProductionBox">
                <Typography className='numberListProduction'>
                  1
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
                  <Box sx={{ rowGap: '5px' }}>
                    <Typography className='titleListProduction' onMouseEnter={() => handleMouseEnter(0)}>
                      {dictionary.titleContentProduction1}
                    </Typography>
                    <Typography className="flexoRegular">
                      {dictionary.contentProduction1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FadeRight>
            <FadeRight duration='700'>
              <Box className="listProductionBox">
                <Typography className='numberListProduction'>
                  2
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ rowGap: '5px' }}>
                    <Typography className='titleListProduction' onMouseEnter={() => handleMouseEnter(1)}>
                      {dictionary.titleContentProduction2}
                    </Typography>
                    <Typography className="flexoRegular">
                      {dictionary.contentProduction2}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FadeRight>
            <FadeRight duration='700'>
              <Box className="listProductionBox">
                <Typography className='numberListProduction'>
                  3
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ rowGap: '5px' }}>
                    <Typography className='titleListProduction' onMouseEnter={() => handleMouseEnter(2)}>
                      {dictionary.titleContentProduction3}
                    </Typography>
                    <Typography className="flexoRegular">
                      {dictionary.contentProduction3}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FadeRight>
            <FadeRight duration='700'>
              <Box className="listProductionBox">
                <Typography className='numberListProduction' >
                  4
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ rowGap: '5px' }}>
                    <Typography className='titleListProduction' onMouseEnter={() => handleMouseEnter(3)}>
                      {dictionary.titleContentProduction4}
                    </Typography>
                    <Typography className="flexoRegular">
                      {dictionary.contentProduction4}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FadeRight>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default WaterDrop
